import React from "react";
import Layout from "../../components/chat-channel/Layout";
import { Helmet } from "react-helmet";
import Container from "../../components/chat-channel/Container";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { RequestForm } from "../../components/form";
import {GoToPopup} from "../homepage"
const card1 = require("../../assets/images/solutions/Image_50.png");
const card2 = require("../../assets/images/solutions/Image_51.png");
const card3 = require("../../assets/images/solutions/Image_52.png");
const card4 = require("../../assets/images/solutions/Image_53.png");

const graphic = require("../../assets/images/solutions/automation-graphic.png");
const support = require("../../assets/images/solutions/seemless-suport-graphic.png");
export default function HomePage() {
  return (
    <>
      <TitleAndMetaTags
        title="Ticket Deflection - Automated Resolution with AI Chatbot | Workativ Assistant"
        description="Deflect tickets away from helpdesk. Automate your IT helpdesk with Workativ Assistant"
        keywords={["IT Helpdesk Chatbot", "HR Chatbot", "Service Desk Chatbot"]}
        ogTitle="Ticket Deflection - Automated Resolution with AI Chatbot | Workativ Assistant"
        ogDescription="Deflect tickets away from helpdesk. Automate your IT helpdesk with Workativ Assistant"
      />
      <Container>
      <Layout logoFor="ASSISTANT">
          <section className="solutions_header">
            <div className="col-md-12">
              <div className="row">
                <div className="col-lg-4 col-md-4 pl-0 w-30">
                  <div className="solutions_left_img"></div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-12 w-40">
                  <div className="solutions_center">
                    <h1>
                      Eliminate frequent calls to the helpdesk with AI driven
                      self-service
                    </h1>
                    <p>
                      AI based Self-service is proving to be a useful model in
                      reducing the number of tickets that reach the helpdesk.
                      Give workplace support a boost and enable employees to
                      self-resolve issues on their own with an automated digital
                      assistant.
                    </p>
                    <div className="solutions_form">
                      <div className="main-slider-button beta-button">
                        <RequestForm isFooterForm={false} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 pr-0 w-30">
                  <div className="solutions_right_img"></div>
                </div>
              </div>
            </div>
          </section>
          <Cards />
          <CardsSolution />
          <Benefits />
          <ReadyChat />
          <GoToPopup />
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}

const cardTwo = [
  {
    header: "Deflect tickets with automated resolution",
    content:
      "Workativ Assistant integrates with various applications with out of the box automations to fully automate issue resolution and have requests completed in seconds.",
    image: graphic,
    alt: "Intelligent self-service for employees"
  },
  {
    header: "Provide uninterrupted support. Don’t trouble the agents.",
    content:
      "Employees can initiate a chat with an intelligent digital assistant that gets the job done, leaving the IT Help Desk team to focus on more pressing issues.",
    image: support,
    alt: "Deflect calls away from helpdesk"
  }
];

export function Cards() {
  return (
    <section className="solution_service">
      <div className="container">
        <div className="col-md-12 col-12">
          <div className="row">
            {cardTwo.map(card => (
              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="solution_service_card">
                  <h5>{card.header}</h5>
                  <p>{card.content}</p>
                  <img loading = "lazy" src={card.image} alt={card.alt} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

const cardSolution = [
  {
    image: card3,
    content: "A convenient way for employees to self-resolve issues"
  },
  {
    image: card1,
    content: "User satisfaction and more productive employees"
  },
  {
    image: card2,
    content: "Free up service desk resources for more critical tasks"
  },
  {
    image: card4,
    content: "Save costs and time"
  }
];

CardsSolution.Header = ({ children }) => {
  return <h4>{children}</h4>;
};
export function CardsSolution({ children }) {
  return (
    <>
      <section className="conversational_facelift">
        <div className="container">
          <div className="col-md-12 col-12 conversational_facelift_header">
            <CardsSolution.Header>
              Improve employee experience
            </CardsSolution.Header>
            <div className="ai_without_scroll">
              <div className="row">
                {cardSolution.map(card => (
                  <div className="col-lg-3 col-md-3 col-sm-6 col-12 pad_25">
                    <div className="conversational_facelift_card">
                      <img loading = "lazy" src={card.image} alt="conversational AI" />
                      <p>{card.content}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="x_scroll_mobile">
              <div className="cards_solutions_x_scroll">
                {cardSolution.map(card => (
                  <div className="conversational_facelift_card_scroll">
                    <img loading = "lazy" src={card.image} alt="conversational AI" />
                    <p>{card.content}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export function Benefits() {
  return (
    <section className="Integrations_card solutions_integrations_card">
      <div className="container">
        <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-12">
          <h4>So much more than benefits</h4>
          <div className="row">
            <div className="col-md-6 col-lg-3 col-sm-6 col-xs-12 col-12 ">
              <a
                className="integrations_card_desk solutions_card_desk no-overeffect-link url_manipulation"
                onclick="ga('send', 'event', 'helpdesk-automation', 'body', 'AI Self Service', {'nonInteraction': 1});"
                href="/conversational-ai-platform/self-service-automation"
              >
                <div className="card_office solutions_card">
                  <img loading = "lazy"
                    src={require("../../assets/images/solutions/Image_54.png")}
                    alt="AI Self Service"
                  />
                </div>
                <div className="card_head solutions_card">
                  <h5>AI Self Service</h5>
                  <p className="card-text">
                    Provide a modern self-service experience to your employees
                  </p>
                  <div classs="wv-more-txt">Read more</div>
                </div>
              </a>
            </div>
            <div className="col-md-6 col-lg-3 col-sm-6 col-xs-12 col-12 ">
              <a
                className="integrations_card_desk solutions_card_desk no-overeffect-link url_manipulation"
                onclick="ga('send', 'event', 'helpdesk-automation', 'body', 'Automated Resolution', {'nonInteraction': 1});"
                href="/conversational-ai-platform/automated-resolution"
              >
                <div className="card_office solutions_card">
                  <img loading = "lazy"
                    src={require("../../assets/images/solutions/Image_55.png")}
                    alt="Automated Resolution"
                  />
                </div>
                <div className="card_head solutions_card">
                  <h5>Automated Resolution</h5>
                  <p className="card-text">
                    Free upto 30% of support capacity for higher-value tasks.
                  </p>
                  <div classs="wv-more-txt">Read more</div>
                </div>
              </a>
            </div>
            <div className="col-md-6 col-lg-3 col-sm-6 col-xs-12 col-12 ">
              <a
                className="integrations_card_desk solutions_card_desk no-overeffect-link url_manipulation"
                onclick="ga('send', 'event', 'helpdesk-automation', 'body', 'Call deflection', {'nonInteraction': 1});"
                href="/conversational-ai-platform/call-deflection"
              >
                <div className="card_office solutions_card ">
                  <img loading = "lazy"
                    src={require("../../assets/images/solutions/Image_56.png")}
                    alt="Support Automation"
                  />
                </div>
                <div className="card_head solutions_card">
                  <h5>Call deflection</h5>
                  <p className="card-text">
                    Move repetitive and simple issues away from helpdesk
                  </p>
                  <div classs="wv-more-txt">Read more</div>
                </div>
              </a>
            </div>
            <div className="col-md-6 col-lg-3 col-sm-6 col-xs-12 col-12 ">
              <a
                className="integrations_card_desk solutions_card_desk no-overeffect-link url_manipulation"
                onclick="ga('send', 'event', 'helpdesk-automation', 'body', 'Cost reduction', {'nonInteraction': 1});"
                href="/conversational-ai-platform/cost-reduction"
              >
                <div className="card_office solutions_card">
                  <img loading = "lazy"
                    src={require("../../assets/images/solutions/Image_57.png")}
                    alt="Helpdesk Automation"
                  />
                </div>
                <div className="card_head solutions_card">
                  <h5>Cost reduction</h5>
                  <p className="card-text">
                    An improvised helpdesk provides numerous cost benefits and
                    savings
                  </p>
                  <div classs="wv-more-txt">Read more</div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export function ReadyChat() {
  return (
    <section className="know-how-assistant solutions_assistant">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12 know-how-assistant-content">
            <h3>Ready for a chat?</h3>
            <p>
              See how Workativ Assistant can transform your workplace support
              and reduce your helpdesk costs.
            </p>
            <div className="start-free-trail">
              <a
                href="/assistant/trial-registration"
                className="url_manipulation"
              >
                Request free trial
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 know-how-assistant-image">
            <img loading = "lazy"
              src={require("../../assets/images/solutions/signup-banner-graphic.png")}
              alt="Request free trial"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
